/*
 * @Author: Corn-czy
 * @Date: 2022-07-11 15:30:51
 * @Description: 方法库
 * @FilePath: \zm-data-platform-admin-new\src\utils\methods.js
 */
//删除对象中为空的key值
export const delEmpty = (obj) => {
  let objNew = {};
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] == "" ||
      obj[key] == null ||
      obj[key] == undefined ||
      obj[key] == "undefined" ||
      obj[key] == []
    ) {
      delete obj[key];
    } else {
      objNew[key] = obj[key];
    }
  });
  return objNew;
};
//判断对象里面的KEY是否为空
export const isEmpty = (obj) => {
  let isEmpty = false;
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] == "" ||
      obj[key] == null ||
      obj[key] == undefined ||
      obj[key] == "undefined" ||
      obj[key] == []
    ) {
      isEmpty = true;
    } else {
      isEmpty = false;
    }
  });
  return isEmpty;
};
//对象置空
export const objEmpty = (obj) => {
  Object.keys(obj).forEach((key) => {
    obj[key] = "";
  });
  return obj;
};
//时间格式化
export const formatDate = (fmt, date) => {
  let ret;
  const opt = {
    "Y+": date.getFullYear().toString(), // 年
    "m+": (date.getMonth() + 1).toString(), // 月
    "d+": date.getDate().toString(), // 日
    "H+": date.getHours().toString(), // 时
    "M+": date.getMinutes().toString(), // 分
    "S+": date.getSeconds().toString(), // 秒
    // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
    ret = new RegExp("(" + k + ")").exec(fmt);
    if (ret) {
      fmt = fmt.replace(
        ret[1],
        ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
      );
    }
  }
  return fmt;
};
//时间获取 getDate("yyyy-mm-dd")
export const getDate = (fmt) => {
  const date = new Date();
  const o = {
    "Y+": date.getFullYear(),
    "M+": date.getMonth() + 1, // 月
    "D+": date.getDate(), // 日
    "h+": date.getHours(), // 时
    "m+": date.getMinutes(), // 分
    "s+": date.getSeconds(), // 秒
    W: date.getDay(), // 周
  };
  for (let k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(RegExp.$1, () => {
        if (k === "W") {
          // 星期几
          const week = ["日", "一", "二", "三", "四", "五", "六"];
          return week[o[k]];
        } else if (k === "Y+" || RegExp.$1.length === 1) {
          // 年份 or 小于10不加0
          return o[k];
        } else {
          return ("00" + o[k]).substr(("" + o[k]).length); // 小于10补位0
        }
      });
    }
  }
  return fmt;
};
//GMT转普通格式
export const GMTToStr = (time) => {
  let date = new Date(time);
  let Str =
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getDate() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();
  return Str;
};
//复制
export const copyFn = (val) => {
  var copyInput = document.createElement("input");
  //val是要复制的内容
  copyInput.setAttribute("value", val);
  document.body.appendChild(copyInput);
  copyInput.select();
  try {
    var copyed = document.execCommand("copy");
    if (copyed) {
      document.body.removeChild(copyInput);
      return "复制成功";
    }
  } catch {
    return "复制失败，请检查手动复制";
  }
};
//截取指定字符后的内容
export const getCaption = (obj, text) => {
  var index = obj.lastIndexOf(text ? text : "/default/"); //获取-后边的字符串
  obj = obj.substring(index + Number(text ? text.length : 9), obj.length);
  return obj;
};

//深拷贝
export const deepClone = (obj) => {
  let objClone = Array.isArray(obj) ? [] : {};
  if (obj && typeof obj === "object") {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        //判断ojb子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === "object") {
          objClone[key] = deepClone(obj[key]);
        } else {
          //如果不是，简单复制
          objClone[key] = obj[key];
        }
      }
    }
  }
  return objClone;
};
//数组改变key
export const changeKey = (arr, key) => {
  let newArr = [];
  arr.forEach((item, index) => {
    let newObj = {};
    for (var i = 0; i < key.length; i++) {
      newObj[key[i]] = item[Object.keys(item)[i]];
    }
    newArr.push(newObj);
  });
  return newArr;
};
//数组对象改为各个key为一个数组
export const keyChangeArr = (arr, dayType) => {
  let xArr = [];
  let yArr = [];
  arr.forEach((item, index) => {
    xArr.push(item.num);
    yArr.push(`${item.date.substring(item.date.length - 2)}${dayType}`);
  });
  return [xArr, yArr];
};
