<!--
 * @Author: Corn-czy
 * @Date: 2022-06-22 17:40:26
 * @Description: 自定义tabs
 * @FilePath: \zm-data-platform-admin\src\components\Labels\Labels.vue
-->
<template>
    <div class="lables">
        <span v-for="(item,idx) in tabArr" :key="idx" :class="item.value===activeVal?'active':''" @click="lableChange(item.value)">{{item.lable}}</span>
    </div>
</template>

<script>
export default {
    props: {
        tabArr: {
            type: Array,
            default: () => {
                return [
                    { lable: "近1天", value: 1 },
                    { lable: "近7天", value: 7 },
                    { lable: "近15天", value: 15 },
                ]
            }
        },
        activeVal: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
        }
    },
    methods: {
        lableChange (e) {
            this.$emit("update:activeVal", e)
        }
    }

}
</script>

<style lang="scss" scoped>
.lables {
    width: fit-content;
    border: 1px solid #ebecff;
    border-radius: 4px;
    overflow: hidden;
    span {
        &:last-child {
            border-right: none;
        }
        display: inline-block;
        padding: 6px 16px;
        font-size: 14px;
        border-right: 1px solid #ebecff;
    }

    .active {
        background: #555cd1;
        color: #fff;
        border: 1px solid #555cd1;
    }
}
</style>