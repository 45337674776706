/*
 * @Author: Corn-czy
 * @Date: 2022-05-16 14:30:01
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\api\business.js
 */
import request from '@/utils/request'
/**
 * 频道点击
 */
export const channel_click = (params) => {
    return request({
        url: '/shadow/channel_click.json',
        method: 'get',
        params,
    })
}
/**
 * 功能使用
 */
export const option_click = (params) => {
    return request({
        url: '/shadow/option_click.json',
        method: 'get',
        params,
    })
}
/**
 * app点击
 */
export const app_click = (params) => {
    return request({
        url: '/shadow/app_click.json',
        method: 'get',
        params,
    })
}
/**
 * app累计使用时长
 */
export const app_duration = (params) => {
    return request({
        url: '/shadow/app_duration.json',
        method: 'get',
        params,
    })
}
//城市的接口
export const address = (params) => {
    return request({
        url: '/dict/regions.json',
        method: 'get',
        params,
    })
}
