<!--
 * @Author: Corn-czy
 * @Date: 2022-06-22 16:07:09
 * @Description: 分页组件
 * @FilePath: \zm-data-platform-admin\src\components\Pagination\Pagination.vue
-->
<template>
  <el-pagination
    class="pagination"
    @size-change="sizeChange"
    @current-change="currentChange"
    :current-page="currentPage"
    :page-sizes="pageSizes"
    :page-size="pageSize"
    :layout="layout"
    :total="total"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    //每页显示个数选择器的选项设置
    pageSizes: {
      type: Array,
      default: () => {
        return [10, 20, 30, 50, 100];
      },
    },
    pageSize: {
      type: Number,
      default: 20,
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    total: {
      type: Number,
      default: 100,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    //每页条数改变的时候
    sizeChange(e) {
      this.$emit("sizeChange", e);
    },
    //当前页改变
    currentChange(e) {
      this.$emit("currentChange", e);
    },
  },
};
</script>

<style scoped lang="scss">
.pagination {
  padding: 20px 0 0;
}
</style>
