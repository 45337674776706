<!--
 * @Author: Corn-czy
 * @Date: 2022-06-22 18:40:20
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\components\DatePicker\DatePicker.vue
-->
<template>
    <block>
        <el-date-picker v-if="types == 'days'" :clearable="clearable" key="daterange" v-model="value1" type="daterange"
            :picker-options="pickerOptions" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="yyyy-MM-dd" @change="changeDay" />

        <el-date-picker v-model="value2" :clearable="clearable" v-else-if="types == 'months'" type="monthrange"
            :picker-options="pickerOptions" value-format="yyyy-MM" range-separator="至" start-placeholder="开始月份"
            end-placeholder="结束月份" @change="changeDay" />

        <el-date-picker v-else-if="types == 'month'" :clearable="clearable" v-model="value3" key="month"
            :picker-options="pickerOptions" type="month" value-format="yyyy-MM" placeholder="选择月" @change="changeDay" />
    </block>
</template>

<script>
import { formatDate } from '../../utils/methods'
export default {
    props: {
        types: {
            type: String,
            default: "days"
        },
        clearable: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            value1: "",
            value2: "",
            value3: '',
            pickerOptions: {
                disabledDate:
                    (time) => {
                        if (this.types == 'days') {
                            const year = new Date().getFullYear() // 获取当前年份
                            const mon = new Date().getMonth() + 1// 获取当前月份份
                            const day = new Date().getDate() // 获取当前日期
                            const oneYear = year - 1 // 获取去年
                            const timeYear = new Date(oneYear + '/' + mon + '/' + day).getTime() // 将明年的日期转换成时间戳
                            const newDate = new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1;
                            // 当前日后后面的日子不能选和一年前的今日不能选（不包含本日）
                            return time.getTime() > newDate || time.getTime() < timeYear + 1;
                        }
                        if (this.types !== 'days') {
                            const year = new Date().getFullYear() // 获取当前年份
                            const month = new Date().getMonth() + 1;// 获取当前月份
                            var nextday = new Date(year, month, 0);//
                            var lastday = new Date(year - 1, month, 0);//去年
                            //当前月的前11个月（包含本月）
                            return time.getTime() <= lastday.getTime() || time.getTime() > nextday.getTime();

                        }
                    },
            },
        }
    },
    mounted() {
        this.types == 'months' ? this.months() : this.types == 'month' ? this.month() : this.days()
    },
    watch: {
        types(newval, oldval) {
            newval == 'months' ? this.months() : this.days()
        }
    },
    methods: {
        //日时间段
        days() {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            this.value1 = [formatDate('YYYY-mm-dd', start), formatDate('YYYY-mm-dd', end)]
            this.$emit("changePicker", this.value1, this.types)
        },
        months() {
            const end = new Date();
            const start = new Date();
            start.setMonth(start.getMonth() - 11);
            this.value2 = [formatDate('YYYY-mm', start), formatDate('YYYY-mm', end)]
            this.$emit("changePicker", [this.value2[0] + '-01', this.value2[1] + '-01'], this.types)
        },
        month() {
            const start = new Date();
            this.value3 = formatDate('YYYY-mm', start)
            this.$emit("changePicker", [this.value3 + '-01', this.value3 + '-01'], this.types)
        },
        changeDay(value) {
            if (this.types == 'months') value = [value[0] + '-01', value[1] + '-01']
            if (this.types == 'month') value = [value + '-01', value + '-01']
            this.$emit('changePicker', value, this.types)
        }
    }

}
</script>

<style>

</style>