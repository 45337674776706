<!--
 * @Author: Corn-czy
 * @Date: 2022-06-27 10:27:55
 * @Description: 设备数据
 * @FilePath: \zm-data-platform-admin\src\views\equipment\index.vue
-->
<template>
    <div class="contents">
        <div class="fixed rollfixed" style="z-index: 5000">
            <div class="bg roll firstAll">
                <span :class="modelVal == '全部' ? 'active span spanC firstSpan' : 'span spanC firstSpan'"
                    @click="modelVal = '全部'">全部</span>
                <div class="spanA11">
                    <span :class="modelVal == item ? 'active span spanC' : 'span spanC'" v-for="(item, idx) in typeArr"
                        :key="idx" @click="modelChange(item)">
                        {{ item }}
                    </span>
                </div>
            </div>
        </div>
        <div class="pt100"></div>
        <div class="flexCard mb20">
            <Cards cardName="设备数据概览" cardWidth="65%">
                <div class="ulCard">
                    <div class="li liC" v-for="(item, idx) in overviewArr" :key="idx">
                        <p>
                            {{ idx == 0 ? '昨日新增设备' : idx == 1 ? '昨日平均使用时长' : '昨日活跃设备量' }}
                        </p>
                        <p class="weight">{{ item }}</p>
                    </div>
                </div>
            </Cards>
            <Cards cardName="设备型号占比（台）" cardWidth="33%">
                <div id="proportionE"></div>
            </Cards>
        </div>
        <Cards cardName="设备趋势" cardWidth="100%" class="mb20 alignCenter">
            <template v-slot:right>
                <div class="slotTwo slotTwoC">
                    <Labels :tabArr="yearArr" class="mr20" :activeVal.sync="labActVal" />
                    <DatePicker class="mr20" :types="labActVal == '日' ? 'days' : 'months'" :datePicker.sync="datePicker"
                        @changePicker="(e, type) => changePicker(e, type, 0)" />
                    <Selects :options="trendArr" :activeVal.sync="trendVal" />
                </div>
            </template>
            <div id="trendE" v-if="equTrendRulsut"></div>
            <img src="../../assets/none.png" alt="" width="30%" v-else />
        </Cards>
        <div class="flexCard mb20">
            <Cards cardName="设备地域分布图" cardWidth="65%" style="position: relative">
                <el-button type="text" @click="showChina = true" v-if="!showChina" icon="el-icon-back"
                    class="rutren">返回</el-button>
                <ChinaMap ref="mapInt" @chinaClick="chinaClick" v-if="showChina" :modelVal="modelVal" />
                <ProvinceMap v-else :province="province" :data-list="provinceData" />
            </Cards>
            <Cards cardName="地域排行" cardWidth="33%" class="alignCenter">
                <template v-slot:right>
                    <DatePicker types="month" :datePicker.sync="datePicker"
                        @changePicker="(e, type) => changePicker(e, type, 1)" />
                </template>
                <div class="h600">
                    <blockquote v-if="ranklistPage.length != 0">
                        <div class="center">
                            <Labels :tabArr="territoryArr" :activeVal.sync="territoryVal" class="mb20" />
                            <div class="ul ulC">
                                <div class="li liC" v-for="(item, idx) in ranklistPage" :key="idx"
                                    style="margin: 0.35rem 0">
                                    <div class="progress">
                                        <p class="p">{{ item.province }}</p>
                                        <el-progress class="progressEl70" color="#555CD1" :text-inside="true"
                                            :stroke-width="22" :percentage="item.percent"
                                            v-if="territoryVal == '设备增长量'" />
                                    </div>
                                    <p class="color">
                                        {{ `${item.num}${territoryVal == '设备增长量' ? '台' : '分钟'}` }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Pagination layout="prev, pager, next, jumper" :pageSize="9" :total="ranklist.length"
                            @currentChange="(e) => currentChange(e, 1)" />
                    </blockquote>
                    <img src="../../assets/none.png" alt="" width="50%" v-else />
                </div>
            </Cards>
        </div>
        <Cards cardName="地域数据" cardWidth="100%" class="mb20">
            <template v-slot:right>
                <DatePicker types="days" @changePicker="(e, type) => changePicker(e, type, 2)" />
            </template>
            <el-table :data="tableData.list" style="width: 100%" max-height="550">
                <el-table-column fixed prop="province" label="省份" />
                <el-table-column prop="city" label="城市" />
                <el-table-column prop="count" label="设备数" sortable />
                <el-table-column prop="avg" label="平均使用时长" sortable />
            </el-table>
            <Pagination :pageSize="10" :total="tableData.count" @sizeChange="(e) => sizeChange(e, 2)"
                @currentChange="(e) => currentChange(e, 2)" />
        </Cards>
        <Cards cardName="设备数据" cardWidth="100%" class="mb20">
            <template v-slot:right>
                <div class="slotTwo slotTwoC">
                    <span class="span">SN号：</span>
                    <el-input v-model="queryEqu.sn" clearable @change="changeInput" class="inputSlot mr20"
                        placeholder="请输入SN号" />
                    <DatePicker types="days" @changePicker="(e, type) => changePicker(e, type, 3)" />
                </div>
            </template>
            <el-table :data="equipmentData.list" style="width: 100%" max-height="550">
                <el-table-column fixed prop="sn" label="SN号码" />
                <el-table-column prop="model" label="设备型号" />
                <el-table-column prop="sys" label="固件版本" />
                <el-table-column prop="addr" label="IP所在地" />
                <el-table-column prop="sum" label="总使用时长" />
                <el-table-column prop="avg" label="平均使用时长" />
                <!-- <el-table-column prop="type" label="设备状态" /> -->
                <!-- <el-table-column prop="phone" label="用户手机号" /> -->
                <el-table-column prop="create_time" label="激活时间" />
            </el-table>
            <Pagination :pageSize="10" :total="equipmentData.count" @sizeChange="(e) => sizeChange(e, 3)"
                @currentChange="(e) => currentChange(e, 3)" />
        </Cards>
    </div>
</template>

<script>
import {
    active,
    deviceData,
    duration,
    duration_avg,
    locationData,
    map,
    modelList,
    model_percent,
    new_add_trend,
    overview,
    rankNewAdd
} from '../../api/equipment'
import Cards from '../../components/Cards/Cards.vue'
import ChinaMap from '../../components/chinaMap/index.vue'
import ProvinceMap from '../../components/chinaMap/Province.vue'
import DatePicker from '../../components/DatePicker/DatePicker.vue'
import Labels from '../../components/Labels/Labels.vue'
import Pagination from '../../components/Pagination/Pagination.vue'
import Selects from '../../components/Selects/Selects.vue'
import { changeKey, delEmpty, keyChangeArr } from '../../utils/methods'
export default {
    components: {
        Cards,
        Pagination,
        DatePicker,
        Labels,
        ChinaMap,
        ProvinceMap,
        Selects,
    },
    data() {
        return {
            typeArr: [],
            modelVal: '全部',
            overviewArr: [0, 0, 0],
            datePicker: [],
            //地域数据表格
            tableData: { list: [], count: 0 },
            ranklist: [],
            ranklistPage: [],
            //设备数据表格
            equipmentData: [],
            //设备趋势 tab栏
            trendArr: [
                {
                    label: '设备新增量',
                    value: '设备新增量',
                },
                {
                    label: '使用时长',
                    value: '使用时长',
                },
                {
                    label: '活跃设备量',
                    value: '活跃设备量',
                },
            ],
            trendVal: '设备新增量',
            equTrendRulsut: false, //设备趋势结果
            //地域排行 tab栏
            territoryArr: [
                {
                    lable: '设备增长量',
                    value: '设备增长量',
                },
                {
                    lable: '平均使用时长',
                    value: '平均使用时长',
                },
            ],
            territoryVal: '设备增长量',
            //设备趋势 日月 labels
            yearArr: [
                {
                    lable: '日',
                    value: '日',
                },
                {
                    lable: '月',
                    value: '月',
                },
            ],
            labActVal: '日',
            showChina: true,
            province: '重庆',
            queryObj: {
                grain: 'day',
                start_time: '',
                end_time: '',
            },
            queryRunObj: {
                start_time: '',
                end_time: '',
            },
            mapChina: [],
            queryLocation: {
                page: 1,
                step: 10,
                start_time: '',
                end_time: '',
            },
            queryEqu: {
                page: 1,
                step: 10,
                sn: '',
                start_time: '',
                end_time: '',
            },
            option: {
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    top: 'center',
                    left: '70%',
                    orient: 'vertical',
                    type: 'scroll',
                    data: [],
                    //关键的在这
                    formatter: () => { },
                },
                title: {
                    text: '设备总数',
                    subtext: '0', //副标题
                    x: '24%', //标题x轴居中（也可以是: left || center || right || 百分比：xx% || xx(默认单位px)  ）
                    y: '36%', //标题y轴居中（也可以是: top || center || bottom || 百分比：xx% || xx(默认单位px)  ）
                    itemGap: 5, //主标题和副标题之间的距离(默认为 0)
                    textStyle: {
                        //设置主标题样式
                        fontSize: 14,
                        color: '#666',
                        fontWeight: '400',
                        backroung: 'blue',
                    },
                    subtextStyle: {
                        //设置副标题样式
                        fontSize: 14,
                        color: '#666',
                        fontWeight: 'bold',
                    },
                },
                series: [
                    {
                        name: '设备型号',
                        type: 'pie',
                        radius: ['60%', '90%'],
                        center: ['30%', '50%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 2,
                            borderColor: '#fff',
                            borderWidth: 2,
                        },
                        label: {
                            show: false,
                        },
                        labelLine: {
                            length: 20,
                            length2: 50,
                        },
                        data: [],
                    },
                ],
            },
            echartsSumnum: 0,
        }
    },
    mounted() {
        this.getDatas(true)
    },
    watch: {
        //型号改变
        modelVal(newval) {
            this.queryObj.model = newval == '全部' ? '' : newval
            this.queryRunObj.model = newval == '全部' ? '' : newval
            this.getDatas(false)
        },
        //监听日的变化
        labActVal(newval) {
            this.queryObj.grain = newval == '日' ? 'day' : 'month'
        },
        //设备 设备新增量
        trendVal() {
            this.equTrend()
        },
        //地域排行 设备增长量和平均使用时长
        territoryVal() {
            this.territoryRunk()
        },
    },
    methods: {
        async getDatas(bool) {
            //型号
            if (bool) await this.getModel()
            //设备概览的数据
            await this.overviewGet()
            //设备型号的占比
            await this.modelPercent()
            //设备趋势
            if (!bool) {
                await this.equTrend()
                //地域排行
                await this.territoryRunk()
                //地域表格
                await this.territoryTable()
            }
        },
        //型号
        async getModel() {
            let res = await modelList()
            if (res.status) {
                this.typeArr = res.data
            }
        },
        //设备概览
        async overviewGet() {
            let res = await overview(delEmpty({ model: this.modelVal == '全部' ? '' : this.modelVal }))
            if (res.status) {
                let data = res.data
                this.overviewArr = [data.device_active, data.duration_avg, data.device_new_add]
            }
        },
        //设备型号占比图接口
        async modelPercent() {
            let res = await model_percent()
            if (res.status) {
                this.echartsSumnum = 0
                let arr = changeKey(res.data, ['value', 'name'])
                arr.forEach((item) => {
                    this.echartsSumnum = this.echartsSumnum + item.value
                })
                this.proportionE(arr)
            }
        },
        //型号选择
        modelChange(e) {
            this.modelVal = e
        },

        //设备型号占比（台）
        proportionE(arr) {
            this.option.title.subtext = this.echartsSumnum
            this.option.series[0].data = arr
            this.option.legend.data = arr
            this.option.legend.formatter = (name) => {
                //通过name获取到数组对象中的单个对象
                let singleData = arr.filter(function (item) {
                    return item.name == name
                })
                return `${name} 「${singleData[0].value}」`
            }

            const chartBox = this.$echarts.init(document.getElementById('proportionE'))
            chartBox.on('legendselectchanged', (params) => {
                let data = arr.filter((item) => item.name == params.name)
                this.echartsSumnum = Number(`${params.selected[params.name] ? this.echartsSumnum + data[0].value : this.echartsSumnum - data[0].value}`)
                this.option.title.subtext = this.echartsSumnum
                this.$nextTick(() => {
                    chartBox.setOption(this.option)
                })
            })

            chartBox.setOption(this.option)
            // 根据页面大小自动响应图表大小
            window.addEventListener('resize', function () {
                chartBox.resize()
            })
        },
        //设备总数
        cuont(type, num) {
            return `设备总数\n\n${type ? this.echartsSumnum + num : this.echartsSumnum - num} `
        },
        //趋势的月
        async changePicker(value, type, num) {
            this.datePicker = value
            if (type == 'days') {
                if (num == 0) {
                    this.queryObj.start_time = value[0]
                    this.queryObj.end_time = value[1]
                    this.equTrend()
                }
                if (num == 2) {
                    this.queryLocation.page = 1
                    this.queryLocation.start_time = value[0]
                    this.queryLocation.end_time = value[1]
                    this.territoryTable()
                }
                if (num == 3) {
                    this.queryEqu.page = 1
                    this.queryEqu.start_time = value[0]
                    this.queryEqu.end_time = value[1]
                    this.equipmentTable()
                }
            } else if (type == 'month') {
                this.queryRunObj.start_time = value[0]
                this.queryRunObj.end_time = value[1]
                this.territoryRunk()
            } else if (type == 'months') {
                if (num == 0) {
                    this.queryObj.start_time = value[0]
                    this.queryObj.end_time = value[1]
                    this.equTrend()
                }
            }
        },
        //设备增长量和平均使用时长的接口
        async territoryRunk() {
            let res = this.territoryVal == '设备增长量' ? await rankNewAdd(delEmpty(this.queryRunObj)) : await duration_avg(delEmpty(this.queryRunObj))
            if (res.status) {
                if (res.data.length != 0) {
                    this.ranklist = res.data.map((item) => {
                        item.province = item.province.replace(/省|市|自治区|特别行政区|壮族|回族|维吾尔/g, '')
                        if (this.territoryVal == '设备增长量') item.percent = item.percent.replace(/%/g, '')
                        return item
                    })
                    this.ranklistPage = this.ranklist.slice(0, 9)
                } else {
                    this.ranklist = []
                    this.ranklistPage = []
                }
            }
        },
        //设备趋势设备新增量接口
        async equTrend() {
            let res =
                this.trendVal == '设备新增量'
                    ? await new_add_trend(delEmpty(this.queryObj))
                    : this.trendVal == '使用时长'
                        ? await duration(delEmpty(this.queryObj))
                        : await active(delEmpty(this.queryObj))
            if (res.status) {
                this.equTrendRulsut = res.data.length != 0 ? true : false
                setTimeout(() => {
                    let rulsut = keyChangeArr(res.data, this.labActVal)
                    this.trendE(rulsut[0], rulsut[1])
                }, 10)
            }
        },
        //设备趋势
        trendE(xdata, ydata) {
            const chartBox = this.$echarts.init(document.getElementById('trendE'))
            const option = {
                tooltip: {
                    trigger: 'axis',
                    formatter: '{a} <br/>{b} : {c}',
                },
                xAxis: {
                    type: 'category',
                    data: ydata,
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        name: '设备趋势',
                        type: 'line',
                        stack: 'Total',
                        data: xdata,
                        smooth: true,
                        areaStyle: {},
                    },
                ],
            }
            chartBox.setOption(option)
            // 根据页面大小自动响应图表大小
            window.addEventListener('resize', function () {
                chartBox.resize()
            })
        },
        //获取省市的数据
        async getmap(obj) {
            let res = await map(delEmpty(obj))
            if (res.status) {
                this.provinceData = changeKey(res.data, ['value', 'name'])
                this.showChina = false
            }
        },
        //点击地图得到省份
        chinaClick(params) {
            this.province = params.name
            this.getmap({
                province: params.name,
                model: this.modelVal == '全部' ? '' : this.modelVal,
            })
        },

        //地域数据表格
        async territoryTable() {
            let res = await locationData(delEmpty(this.queryLocation))
            if (res.status) {
                this.tableData.count = res.data.count
                this.tableData.list = res.data.list.map((item) => {
                    item.avg = `${(item.avg / 60).toFixed(2)} 小时`
                    return item
                })
            }
        },
        //snhao
        changeInput() {
            this.queryEqu.page = 1
            this.equipmentTable()
        },
        //设备数据表格
        async equipmentTable() {
            let res = await deviceData(delEmpty(this.queryEqu))
            if (res.status) {
                this.equipmentData = res.data
            }
        },
        //分页
        sizeChange(e, type) {
            if (type == 2) {
                this.queryLocation.step = e
                this.territoryTable()
            }
            if (type == 3) {
                this.queryEqu.step = e
                this.equipmentTable()
            }
        },
        currentChange(e, type) {
            switch (type) {
                case 1:
                    this.ranklistPage = this.ranklist.slice((e - 1) * 9, e * 9)
                    break
                case 2:
                    this.queryLocation.page = e
                    this.territoryTable()
                    break
                case 3:
                    this.queryEqu.page = e
                    this.equipmentTable()
                    break
            }
        },
    },
}
</script>

<style lang="scss" scoped>
#trendE {
    width: 100%;
    height: 450px;
}

#proportionE {
    width: 100%;
    height: 144px;
}

.rutren {
    position: absolute;
    z-index: 100;
    color: #555cd1;
}

.ulC {
    min-height: 490px;
}
</style>
