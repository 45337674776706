<!--
 * @Author: Corn-czy
 * @Date: 2022-06-22 16:45:30
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\components\Selects\Selects.vue
-->
<template>
    <blockquote style="display:inline-block" class="iszIndex">
        <el-select v-model="activeVal" clearable :placeholder="placeholder" filterable @change="selectChange"
            v-if="types=='select'" :popper-append-to-body="false">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
        </el-select>
        <el-cascader :options="addressArr"
            :props="{ checkStrictly: true, value: 'name', expandTrigger: 'hover', label: 'name' }" clearable
            popper-class="cascader" v-else @change="changeCascader" ref="cascader" />
    </blockquote>
</template>

<script>
import { address } from '../../api/shadow'
export default {
    props: {
        //默认为性别选择
        options: {
            type: Array,
            default: () => {
                return [{
                    value: '男',
                    label: '男'
                }, {
                    value: '女',
                    label: '女'
                }]
            }
        },
        placeholder: {
            type: String,
            default: "请选择"
        },
        activeVal: {
            type: String,
            default: ""
        },
        types: {
            type: String,
            default: "select"
        }
    },
    data() {
        return {
            addressArr: []
        }
    },
    mounted() {
        if (this.types == 'cascader') {
            this.getAddress()
        }
    },
    watch: {

    },
    methods: {
        async getAddress() {
            let res = await address()
            if (res.status) {
                this.addressArr = res.data
            }
        },
        //选中的id
        selectChange(e) {
            this.$emit("update:activeVal", e)
        },
        //级联
        changeCascader(e) {
            this.$emit("changeCascader", e.length == 2 ? { province: e[0], city: e[1] } : e.length == 1 ? { province: e[0], city: undefined } : { province: undefined, city: undefined })
        }
    }

}
</script>

<style lang="scss" >
::v-deep .iszIndex .sleect-option {
    z-index: 299999999999 !important;
}

.cascader {
    z-index: 299999999999 !important;
}
</style>