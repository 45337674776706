<!--
 * @Author: Corn-czy
 * @Date: 2022-06-23 11:37:20
 * @Description: 卡片
 * @FilePath: \zm-data-platform-admin\src\components\Cards\Cards.vue
-->
<template>
    <el-card class="box-card" shadow="hover" :style="{width:cardWidth}">
        <div slot="header" class="clearfix">
            <span class="cardTitle">{{cardName}}</span>
            <slot name="right" />
        </div>
        <slot />
    </el-card>
</template>

<script>

export default {
    props: {
        cardName: {
            type: String,
            default: "标题"
        },
        cardWidth: {
            type: String,
            default: "80%"
        },
    }
}
</script>

<style lang="scss" scoped>
::v-deep .el-card__header {
    padding: 6px 10px !important;
    .cardTitle {
        font-weight: 600;
        line-height: 36px;
    }
    .clearfix {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.box-card {
    border-radius: 10px;
    height: fit-content;
}
</style>