<template>
    <div id="mapP" />
</template>

<script>
export default {
    props: {
        province: {
            type: String,
            default: '广东'
        },

        dataList: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            chart: null
        }
    },
    watch: {
        dataList: function (val) {
            this.setOption()
        }
    },
    mounted () {
        this.initChart()
    },
    beforeDestroy () {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart () {
            const filaName = {
                浙江: 'zhejiang.js',
                安徽: 'anhui.js',
                澳门: 'aomen.js',
                北京: 'beijing.js',
                重庆: 'chongqing.js',
                福建: 'fujian.js',
                甘肃: 'gansu.js',
                广东: 'guangdong.js',
                广西: 'guangxi.js',
                贵州: 'guizhou.js',
                海南: 'hainan.js',
                河北: 'hebei.js',
                黑龙江: 'heilongjiang.js',
                河南: 'henan.js',
                湖北: 'hubei.js',
                湖南: 'hunan.js',
                江苏: 'jiangsu.js',
                江西: 'jiangxi.js',
                吉林: 'jilin.js',
                辽宁: 'liaoning.js',
                内蒙古: 'neimenggu.js',
                宁夏: 'ningxia.js',
                青海: 'qinghai.js',
                山东: 'shandong.js',
                上海: 'shanghai.js',
                山西: 'shanxi.js',
                山西1: 'shanxi1.js',
                四川: 'sichuan.js',
                台湾: 'taiwan.js',
                天津: 'tianjin.js',
                香港: 'xianggang.js',
                新疆: 'xinjiang.js',
                西藏: 'xizang.js',
                云南: 'yunnan.js'
            }
            require(`../../assets/js/province/${filaName[this.province]}`)
            this.chart = this.$echarts.init(document.getElementById('mapP'))
            this.setOption()
        },
        randomValue () {
            return Math.round(Math.random() * 1000)
        },
        setOption () {
            const option = {
                tooltip: {
                    formatter: function (params, ticket, callback) {
                        if (!params.value) {
                            params.value = '-'
                        }
                        return (
                            params.seriesName + '<br />' + params.name + '：' + params.value
                        )
                    } // 数据格式化
                },
                visualMap: {
                    min: 0,
                    max: 1500,
                    left: 'left',
                    top: 'bottom',
                    text: ['高', '低'], // 取值范围的文字
                    inRange: {
                        color: ['#e0ffff', '#006edd'] // 取值范围的颜色
                    },
                    show: true // 图注
                },
                geo: {
                    map: this.province,
                    roam: false, // 不开启缩放和平移
                    zoom: 1.23, // 视角缩放比例
                    label: {
                        normal: {
                            show: true,
                            fontSize: '10',
                            color: 'rgba(0,0,0,0.7)'
                        }
                    },
                    itemStyle: {
                        normal: {
                            borderColor: 'rgba(0, 0, 0, 0.2)'
                        },
                        emphasis: {
                            areaColor: '#95ec69', // 鼠标选择区域颜色
                            shadowOffsetX: 0,
                            shadowOffsetY: 0,
                            shadowBlur: 20,
                            borderWidth: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                },
                series: [
                    {
                        name: '数量',
                        type: 'map',
                        geoIndex: 0,
                        data: this.dataList
                    }
                ]
            }
            this.chart.setOption(option)
        }
    }
}
</script>

<style>
#mapP {
    width: 100%;
    height: 600px;
}
</style>
