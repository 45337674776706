/*
 * @Author: Corn-czy
 * @Date: 2022-05-16 14:32:42
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\api\equipment.js
 */
import request from "@/utils/request";
//型号列表
export const modelList = (params) => {
  return request({
    url: "/device/model/list.json",
    method: "get",
    params,
  });
};
//设备型号占比图接口
export const model_percent = (params) => {
  return request({
    url: "/device/model_percent.json ",
    method: "get",
    params,
  });
};

//设备数据概览接口
export const overview = (params) => {
  return request({
    url: "/device/overview.json",
    method: "get",
    params,
  });
};
//设备趋势设备新增量接口
export const new_add_trend = (params) => {
  return request({
    url: "/device/trend/new_add.json",
    method: "get",
    params,
  });
};
// 使用时长接口
export const duration = (params) => {
  return request({
    url: "/device/trend/duration.json",
    method: "get",
    params,
  });
};
//设备活跃量
export const active = (params) => {
  return request({
    url: "/device/trend/active.json",
    method: "get",
    params,
  });
};
// 地图数据接口
export const map = (params) => {
  return request({
    url: "/device/map.json",
    method: "get",
    params,
  });
};
// 地域排行设备增长量选项
export const rankNewAdd = (params) => {
  return request({
    url: "/device/location/rank/new_add.json",
    method: "get",
    params,
  });
};
// 地域排行平局使用时长
export const duration_avg = (params) => {
  return request({
    url: "/device/location/rank/duration_avg.json",
    method: "get",
    params,
  });
};
//城市数据列表
export const locationData = (params) => {
  return request({
    url: "/device/location/data.json",
    method: "get",
    params,
  });
};
// 设备数据列表
export const deviceData = (params) => {
  return request({
    url: "/device/data.json",
    method: "get",
    params,
  });
};

//=================
//设备管理
//获取产品列表
export const productList = (params) => {
  return request({
    url: "/iot/product/list.json",
    method: "get",
    params,
  });
};
//获取设备列表
export const deviceLists = (params) => {
  return request({
    url: "/iot/device/list/sql.json",
    method: "get",
    params,
  });
};

export const deviceList = (params) => {
  return request({
    url: "/iot/device/list/product.json",
    method: "get",
    params,
  });
};
//获取设备详情
export const deviceDetail = (params) => {
  return request({
    url: "/iot/device/detail.json",
    method: "get",
    params,
  });
};
//日志开关
export const logSwitch = (data) => {
  return request({
    url: "/iot/device/log_switch.json",
    method: "post",
    data,
  });
};
//获取设备日志开关状态
export const getLogSwitch = (params) => {
  return request({
    url: "/iot/device/get_log_switch.json",
    method: "get",
    params,
  });
};
//获取设备日志文件
export const getLogFile = (params) => {
  return request({
    url: "/iot/device/get_log_file.json",
    method: "get",
    params,
  });
};
//抓取日志
export const catchLog = (data) => {
  return request({
    url: "/iot/device/catch_log.json",
    method: "post",
    data,
  });
};
//编辑日志文件备注
export const editRemark = (data) => {
  return request({
    url: "/iot/device/edit_log_remark.json",
    method: "post",
    data,
  });
};
