<!--
 * @Author: Corn-czy
 * @Date: 2022-06-30 14:46:24
 * @Description: file content
 * @FilePath: \zm-data-platform-admin\src\components\chinaMap\index.vue
-->
<template>
    <div id="mapE" />
</template>

<script>

import '../../assets/js/china'

import { overview, model_percent, new_add_trend, duration, active, map } from '../../api/equipment'
import { changeKey, delEmpty } from '../../utils/methods'
export default {
    props: {
        modelVal: {
            type: String,
            default: "全部"
        }
    },
    data () {
        return {
            dataList: [],
            chart: null,
            dataListMax: 0
        }
    },
    watch: {
        dataList () {
            this.setOption()
        },
        modelVal () {
            this.getmap()
        }
    },
    mounted () {
        this.getmap()
    },
    beforeDestroy () {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        //获取地图
        async getmap () {
            let res = await map(delEmpty({ model: this.modelVal == "全部" ? '' : this.modelVal }))
            if (res.status) {
                if (res.data.length != 0) {
                    this.dataList = changeKey(res.data, ['value', 'name', 'percent']).map(item => {
                        item.name = item.name.replace(/省|市|自治区|特别行政区|壮族|回族|维吾尔/g, "");
                        return item
                    })
                    this.initChart()
                } else {
                    this.dataList = this.dataList.map(item => {
                        item.percent = '0.00%'
                        item.value = 0
                        return item
                    })
                    this.initChart()
                }
            }
        },
        initChart () {
            let new_data_list = this.dataList.map((item, index) => {
                return item.value
            })
            this.dataListMax = Math.max(...new_data_list);
            this.chart = this.$echarts.init(document.getElementById('mapE'));
            this.chart.on('click', params => {
                this.$emit('chinaClick', params)
            })
            this.setOption()
        },
        randomValue () {
            return Math.round(Math.random() * 1000)
        },
        setOption () {
            const option = {
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        let num
                        let showHtml = ''
                        if (isNaN(params.value)) {
                            num = '0'
                        } else {
                            num = params.value
                        }
                        showHtml += `
                        <span style="display: flex;">
                            ${'省份'}：${params.name}</br>
                            ${'数量'}：${num}</br>
                            ${'比例'}：${params.data.percent}
                        </span>
                    `
                        return showHtml

                    }
                },
                dataRange: {
                    x: 'left',
                    y: 'bottom',
                    min: 0,
                    max: this.dataListMax,
                    text: ['高', '低'], // 文本，默认为数值文本
                    calculable: true,
                    inRange: {
                        color: ['#d1e7fe', '#1989fa'],

                    }
                },
                series: [{
                    name: '数据',
                    type: 'map',
                    mapType: 'china',
                    roam: false,
                    selectedMode: false,
                    itemStyle: {
                        normal: {
                            label: {
                                show: true,
                                textStyle: {
                                    color: 'black'
                                }
                            }
                        },
                        emphasis: {
                            areaColor: '#95ec69',
                            label: {
                                show: true

                            }
                        }
                    },
                    data: this.dataList
                }]
            };
            this.chart.clear()
            this.chart.setOption(option)
            // 根据页面大小自动响应图表大小
            window.addEventListener("resize", function () {
                this.chart.resize();
            });
        }
    }


}
</script>

<style >
#mapE {
    width: 100%;
    height: 600px;
}
</style>